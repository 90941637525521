﻿.location-selector-page {
  header {
    .header-container {
      background-color: var(--color-red);
    }

    .header-actions {
      a {
        color: var(--font-color);
        padding-right: var(--padding-small);
      }
    }
  }

  .location-item {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: var(--padding-small);
    text-decoration: none;

    .link {
      text-decoration: underline;
    }
  }

  @media (min-width: $screen-medium) {
    header {
      .header-container {
        padding-left: var(--padding-medium);
      }
    }

    .location-item {
      padding: var(--padding-medium);
    }
  }
}
